
import axios from 'axios'

const mapService = axios.create({
  baseURL: '/weather', // 请求url
  timeout: 3000 // 超时处理
  // withCredentials: true // 是否跨域
})

// 添加请求拦截器
mapService.interceptors.request.use(config => {
  return config
}, error => {
  return Promise.reject(error)
})

// 添加响应拦截器
mapService.interceptors.response.use(res => {
  return res.data
}, error => {
  return Promise.reject(error)
})

export default mapService
