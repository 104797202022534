import service from '@/utils/service'
import mapService from '@/utils/mapService'

export function getPoiTypes() {
  return service({
    url: `/front-poi-v2/poitypesApi/getTypes`,
    method: 'get'
  })
}

export function getPoi(params) {
  return service({
    url: `/front-poi-v2/poisApi/get_all`,
    params
  })
}

export function getRoutesPoi(params) {
  return service({
    url: `/front-poi-v2/routesApi/get_all`,
    params
  })
}

export function getMapsPoi(params) {
  return service({
    url: `/front-poi-v2/mapsApi/get_all`,
    params
  })
}

export function getMaps(params) {
  return service({
    url: `/front-poi-v2/mapInfo/get_map`,
    params
  })
}

export function getMapStyle(params) {
  return service({
    url: `/front-poi-v2/stylesApi/getStyles`,
    params
  })
}

export function getMapWeather(params) {
  return mapService({
    url: `/v1/`,
    params
  })
}
