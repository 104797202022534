<template>
  <div class="warp-bg">
    <van-nav-bar
      title="线路推荐"
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="goback">
      <p @click="onClickLeft">返回</p>
    </div>
    <div v-if="routeShow.image" class="banner"><img :src="routeShow.image"></div>
    <div class="white-bg">
      <div class="show-title">
        <h1>{{ routeShow.name }}</h1>
        <p v-if="routeShow.created_at"><img src="/static/images/infoShow02.png">发布日期：{{ routeShow.created_at.substr(0,10) }}</p>
        <p><img src="/static/images/infoShow01.png">线路总长：{{ routeShow.length }}km</p>
      </div>
    </div>

    <div class="white-bg">
      <div class="show-info" @click="previewClick" v-html="routeShow.introduction" />
    </div>

  </div>
</template>

<script>
import { getRoutesPoi } from '@/api/mapV2.js'
import { ImagePreview } from 'vant'
import { NavBar } from 'vant'

const defaultShow = {
  title: '',
  image: '',
  praise: '',
  body: '',
  id: null
}

export default {
  name: 'MapLine',
  components: {
    [ImagePreview.Component.name]: ImagePreview.Component,
    [NavBar.name]: NavBar
  },
  data() {
    return {
      routeId: '',
      routeShow: Object.assign({}, defaultShow),
      ratings: 4
    }
  },
  watch: {
    $route: 'getShow'
  },
  created() {
    this.getShow()
    window.jumpLink = (val) => {
      const url = val.split('?')
      const params = url[1] ? url[1] : url[0]
      this.$router.push({ path: `/mapHome?${params}` })
    }
  },
  methods: {
    onClickLeft() {
      this.$router.push({ path: `/mapHome` })
    },
    async previewClick(e) {
      if (e.target.tagName === 'IMG') {
        ImagePreview({
          images: [
            e.target.src
          ],
          closeable: true
        })
      }
    },
    async getShow() {
      this.routeId = this.$route.params && this.$route.params.id
      const param = {
        id: this.routeId
      }
      const res = await getRoutesPoi(param)
      this.routeShow = res.payload.list[0]
      this.routeShow.introduction = this.routeShow.introduction.replace(/href="(.*?)"/g, "onclick=\"jumpLink('$1')\"")
    }
  }
}
</script>

<style lang="less" scoped>
@import '~@/styles/public.less';
.goback{ width: 70px; height: 70px; position: fixed; bottom: 5%; right: 0; background: rgba(0, 0, 0, .5);}
.goback p{ font-size: 20px; line-height: 70px; text-align:center; color: #fff;}
.banner{
  img{ width: 100%; height: auto}
}
.white-bg{ .autoHeight(); margin-top:20px;  background :@white; padding: 0;}
.show-title{
  width :100%; position: relative; padding :20px;
  h1{ font-size :24px; line-height :50px; color :#333; padding-bottom :10px; border-bottom :1px solid #eee; margin-bottom: 10px; font-weight: bold; }
  p{ font-size: 16px; line-height: 30px; display: flex; color: #333; padding: 5px 0;
    img{ width: auto; height: 26px; margin-right: 10px; margin-top: 2px;}
  }
}
/deep/.show-info{
  width :100%;
  padding :10px 4% 20px;
  max-width:100% !important;
  h1,h2,h3,h4,h5,h6{ margin-top :5px; }
  p{ font-size :18px; line-height :34px; color :@black3;  margin-top :5px; }
  img{ max-width :100%; padding: 5px 0;}
}
</style>

<style lang="less" scoped>
// @import '~@/styles/public.less';
// .goback{ width: 1rem; height: 1rem; position: fixed; bottom: 5%; right: 0; background: rgba(0, 0, 0, .5);}
// .goback p{ font-size: 0.28rem; line-height: 1rem; text-align:center; color: #fff;}
// .banner{
//   img{ width: 100%; height: auto}
// }
// .white-bg{ .autoHeight(); margin-top: 0.2rem;  background :@white; padding: 0;}
// .show-title{
//   width :100%; position: relative; padding :0.2rem;
//   h1{ font-size :0.4rem; line-height :.6rem; color :#333; padding-bottom :0.15rem; border-bottom :1px solid #eee; margin-bottom: 0.15rem; font-weight: bold; }
//   p{ font-size: 0.26rem; line-height: 0.4rem; display: flex; color: #333; padding: 0.05rem 0;
//     img{ width: auto; height: 0.36rem; margin-right: 0.1rem; margin-top: 0.02rem;}
//   }
//   span{ font-size: 0.24rem; padding: 0 0.2rem; border-radius: 0.5rem; margin-right: 0.1rem; color: #fff;}
//   .show-col1{ background: #6F85FF;}
//   .show-col2{ background: #FFA717;}
//   h3{ position: absolute; right: 0.2rem; bottom: 0.1rem; line-height: 0.4rem; font-size: 0.24rem; color: #333;
//     img{ width: auto; height: 0.4rem; margin-right: 0.05rem;}
//   }
// }
// /deep/.show-info{
//   width :100%;
//   padding :0.01rem 4% 0.2rem;
//   max-width:100% !important;
//   h1,h2,h3,h4,h5,h6{ margin-top :0.15rem; }
//   p{ font-size :0.3rem; line-height :.54rem; color :@black3;  margin-top :0.15rem; }
//   img{ max-width :100%; padding: 0.15rem 0;}
// }
// .show-text{ display: flex; margin: 0 0.2rem; padding: 0.3rem 0; border-top: 1px #eee solid;
//   span{ font-size: 0.24rem; line-height: 0.4rem;}
//   .info-like{
//     img{ width: auto; height: 0.4rem;  margin-right: 0.05rem;}
//   }
//   .info-look{
//     img{ width: auto; height: 0.4rem; margin-left: 0.3rem; margin-right: 0.05rem;}
//   }
// }

// .show-like{ padding: 0.3rem;
//   h2{text-align: center;}
//   img{ width: auto; height: 1rem;}
//   p{text-align: center; color: #999; font-size: 0.28rem; line-height: 0.6rem;}
// }

// .title{ padding: 0.2rem; width :100%;border-bottom : 1px #eee solid;
//   p{
//     height :.6rem;
//     line-height :.6rem;
//     color :@black3;
//     font-size :.34rem;
//   }
// }

// .listHot{ .autoHeight(); height :2rem; padding :0.2rem; display: flex; }
// .list-img{ width :35%; height :1.7rem; .autoHeight(); float :left;
//   img{
//     width :100%;
//     height :1.7rem;
//     border-radius: 5px;
//   }
// }
// .list-txt{
//   width: 65%;
//   height :1.7rem;
//   padding-left: 0.2rem;
//   position : relative;
//   h2{
//     font-size :0.28rem;
//     line-height : 0.42rem;
//     .LimitWord();
//     -webkit-line-clamp :1;
//     color :@black3;
//     a{ color :@black3}
//   }
//   p{
//     font-size :0.24rem;
//     line-height : 0.38rem;
//     padding-top: 0.04rem;
//     .LimitWord();
//     -webkit-line-clamp :2;
//     color :@black3;
//   }
//   h3{ position: absolute; right: 0.1rem; bottom: 0; line-height: 0.3rem; font-size: 0.2rem; color: #333;
//     img{ width: auto; height: 0.3rem; margin-right: 0.05rem;}
//   }
//   .show-order{ width: 100%; display: flex; position: absolute; bottom: 0; left: 0.2rem;
//     span{ width: 28%; font-size: 0.26rem; line-height: 0.3rem; padding-top:0.1rem; }
//     .marker-order{ color: rgba(255, 69, 69, 1); display: flex;
//       img{ width:auto; height: 0.3rem; margin-right: 0.1rem; }
//     }
//     .marker-go{  color: rgba(111, 133, 255, 1); display: flex;
//       img{ width: auto; height: 0.3rem; margin-right: 0.1rem;}
//     }
//   }
// }
</style>

